import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Title, Testonimal, Twitter } from "../../components/Globals";
import { Link } from 'gatsby';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';

const SalesforceMaintenanceSupportServicesPage = () => {

  return (
        <Layout>
             <Helmet>
        <title>Salesforce Maintenance & Support | Cymetrix | USA, India, UK, Japan</title>
        <meta name="description" content="Cymetrix provide SLA based L2 and L3 level support service. Our support engineers provide 24*7 salesforce maintenance and support services to ensure availability of the system."/>
      <meta name="keywords" content="cymetrix,Salesforce maintenance, salesforce support, salesforce maintenance and support, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
      <link rel="canonical" href="https://www.cymetrixsoft.com/services/salesforce-maintenance-support-services/" />
      </Helmet>
            <div className="salesforce-implementation-services-background flex flex-col w-full">
                <p className="block text-base xl:text-3xl xl:px-48 xl:mt-24 md:mt-16 sm:mt-24 lg:text-xl md:text-xl sm:text-lg sma:text-base
                text-white text-uppercase text-center">
                    CONTROL YOUR SALESFORCE OPERATING COSTS

                </p>
                <p className="block text-base xl:text-xl md:mt-2 lg:text-lg md:text-base sm:text-base sma:text-base
                text-white text-uppercase text-center">
                   You focus on core business and let our experts manage your Salesforce implementation.
                </p>
            </div>
            <div className="relative max-w-6xl mx-auto" style={{backgroundColor: '#f6f6f6'}}>
                <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 justify-center">
                    <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                        <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                            <img
                                className="h-72 w-72"
                                src={require('./../../images/services/saleforceApiImg.png')}
                                alt="Hero"
                                />
                        </div>
                    </div>
                    <div className="sma:mx-3 relative lg:w-8/12 flex-shrink-0 lg:text-lef mx-auto justify-center">
                        <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                        Salesforce Maintenance and Support Services
                        </h1><br />
                        <hr className="border-solid border-black mb-1" style={{borderWidth: 0.1}}/>
                        <p className="font-hairline">Salesforce maintenance and support services by Cymetrix are designed to support <Link to="/services/salesforce-implementation-services/" class="underline text-blue-700">Salesforce implementations Service.</Link> That includes implemented systems Sales Cloud, Service Cloud, Marketing Cloud , Force.com applications and related integrations.<br /><br />
                        We have a team of  offshore salesforce development and Salesforce certified consultants, experienced administrators, developers and testers to support.
                        </p><br />
                        <strong>KEY FEATURES OF OUR SALESFORCE SUPPORT SERVICES ARE:</strong>
                        <ul className="list-disc list-inside">
                            <li className="my-2 font-thin">We provide SLA based L2 and L3 level support services.</li>
                            <li className="my-2 font-thin">We follow ITIL framework in our support models.</li>
                            <li className="my-2 font-thin">Our support engineers can provide 24*7 support services to ensure availability of the system.</li>
                            
                        </ul>
                        <div className="bg-transparent hover:border-blue-500 text-gray-700 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="product-background w-full flex flex-col items-center" title="Background image of Salesforce Maintenance & Support Services" alt="
Salesforce Maintenance and support Service Background  | Cymetrix Software
">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Our Salesforce.com support services include:</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">USER MANAGEMENT AND ADOPTION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Job Hierarchy Setup, Adoption Reports and Dashboards, Public Groups, Profiles and Permission sets Management, Queues and Setup.
</div>
                                                         </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">MANAGING CUSTOMIZATIONS AND SUPPORT
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Object connections, creation or alteration of page designs, custom objects, custom links, record types, fields, and catches; Introduction of Junction objects, if necessary</div>
                            

                            </div>
                        </div>

                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">MASS DATA SERVICES 
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Bulk loads and Imports, Mass Transfer.</div>
                              
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">FEATURE ACTIVATION REQUESTS</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Individual records, extended mail merge, multi-money </div>
                               

                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">DATA QUALITY MANAGEMENT and SUPPORT
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Data correction, Approval Processes, Validation Rules, Auto-Response Rules, Automated Workflows, Assignment Rules.
</div>
                               
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">DESKTOP APPS INSTALLATION AND SUPPORT 
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Force.com connect offline, Associate for Microsoft Outlook, interface for Office.</div>
                                      </div>
                        </div>
                       

                        
                        <div class="mt-4 md:w-1/2 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SECURITY MODEL ENHANCEMENTS 
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Session Management, Change and Implement Organization-Wide Defaults, Owner and Criteria 

</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">LEVERAGE STANDARD SALESFORCE OBJECTS & FEATURES

</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Ideas, Campaigns, Products, Assets, Contracts, Leads, Accounts, Opportunities, Activities, Cases, Solutions, and Flows.
</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">EMAIL ADMINISTRATION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Work process email alerts, creation or alteration of email layouts, mail merge formats 
</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SMALL CUSTOM COMPONENTS MANAGEMENT AND SUPPORT</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Visualforce template management and support, Visualforce, triggers, Apex, API; web services 
</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SALESFORCE1 </div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Support and user training, Administration.
</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">APP EXCHANGE PACKAGE SUPPORT
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Suggestions, installation, management, or removal of AppExchange packages including troubleshooting
</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">COMMUNITIES MAINTENANCE
</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Include best practices to enhance UI
</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">ANALYTICS SUPPORT

</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Report types, list views, dashboards, analytic snapshots/reporting snapshots, reports, or any custom report on demand for business users.
</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">END-USER TRAINING</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">End-user and admin training on the implemented system
</div>
                                                         </div>
                        </div>
                        <div class="mt-4 md:w-1/3 lg:w-1/3">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">DOCUMENTATION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Functional specifications documents, implementation documents, release documents and user manuals</div>
                                                         </div>
                        </div>
                    </div>
                    
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mx-auto" style={{backgroundColor: '#f6f6f6'}}>
        <h1 class=" px-20 mt-4 sm:w-1/2 text-2xl text-blue-700 font-bold">Why work with Cymetrix for<br/>
Salesforce maintenance and support</h1>
<div class="flex flex-col lg:flex-row max-w-screen-xl mx-5 py-5 md:py-8 ">
<div class="inline-block" className="px-20 mt-8 sm:w-1/2 text-2xl my-10 text-teal-400" >Reliable delivery</div><div class="md:flex-1" className="line_draw" ></div>
<div class="inline-block " className=" flex bg-teal-400 text-white md:h-32 md:w-90 md:mr-20 md:p-4">Cymetrix Software - Salesforce Consulting and marketing cloud Partners specializes in Salesforce with almost the entire team being Salesforce certified. The best of the class’s global experience of founders reflects in our delivery process rigor.                                                					
</div>
</div>

<div class="flex flex-col lg:flex-row max-w-screen-xl mx-5 py-5 md:py-8 ">
<div class="inline-block" className="px-20 mt-8 sm:w-1/2 text-2xl my-10  text-teal-600" >Adaptability </div><div class="flex-1" className="line_draw" ></div>
<div class="inline-block " className=" flex bg-teal-600 text-white md:h-32 md:w-90 md:mr-20 md:p-4  p-4">We follow Agile Methodology and adopt an iterative strategy.
We have a start-up culture with a passionate team that is always ready to walk that extra mile to deliver Salesforce solutions best suited for the business.
</div>
</div>

<div class="flex flex-col lg:flex-row max-w-screen-xl mx-5 py-5 md:py-8 ">
<div class="inline-block" className="px-20 mt-8 sm:w-1/2 text-2xl my-10 text-blue-400" >Smooth Business
transformation</div><div class="flex-1" className="line_draw" ></div>
<div class="inline-block " className=" flex bg-blue-400 text-white md:h-32 md:w-90 md:mr-20 md:p-4  p-4">We adopt a holistic strategy from advisory to build, trailed by a detailed rollout plan and strong implementation.
</div>
</div>

<div class="flex flex-col lg:flex-row max-w-screen-xl mx-5 py-5 md:py-8 ">
<div class="inline-block" className="px-20 mt-8 sm:w-1/2 text-2xl my-10 text-blue-600">Task Ownership</div><div class="flex-1" className="line_draw" ></div>
<div class="inline-block " className=" flex bg-blue-600 text-white md:h-32 md:w-90 md:mr-20 md:p-4 p-4">We put skin in the game by taking project ownership. That helps us think long-term on behalf of the business and plead passionately for our ideas</div>
</div>

<div class="flex flex-col lg:flex-row max-w-screen-xl mx-5 py-5 md:py-8 ">
<div class="inline-block" className="px-20 mt-8 sm:w-1/2 text-2xl my-10 text-blue-800" >Excellent Experience</div><div class="flex-1" className="line_draw" ></div>
<div class="inline-block " className=" flex bg-blue-800 text-white md:h-32 md:w-90 md:mr-20 md:p-4 p-4">We have an empathetic, professional, and well-rounded team to understand the context of your problem.
</div>
</div>

        
        </div>
            <Testonimal />
            <div className="lg:mt-32 mt-20">
                <Twitter />
            </div>
        </Layout>
    );
}

export default SalesforceMaintenanceSupportServicesPage;